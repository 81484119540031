(() => {
  window.addEventListener(
    'message',
    function(event) {
      if (event.origin !== 'https://demo.arcade.software') return;
      if (event.data.eventName === 'Hotspot Clicked') {
        HockeyStack.goal(
          'Arcade Hotspot Clicked',
          {
            integration: 'Arcade',
            flow_name: event.data.flowName,
            flow_id: event.data.flowId,
            step_id: event.data.stepId,
            hotspot_id: event.data.hotspotId,
            hotspot_label: event.data.hotspot_label
          }
        );
      } else if (event.data.eventName === 'Overlay Clicked') {
        HockeyStack.goal(
          'Arcade Overlay Clicked',
          {
            integration: 'Arcade',
            flow_name: event.data.flowName,
            flow_id: event.data.flowId,
            step_id: event.data.stepId,
            button_label: event.data.buttonLabel,
            link_url: event.data.linkUrl
          }
        );
        HockeyStack.endSession(true);
      } else if (event.data.eventName === 'Step Reached' && event.data.isFinalStep) {
        HockeyStack.goal(
          'Arcade Flow Completed',
          {
            integration: 'Arcade',
            flow_name: event.data.flowName,
            flow_id: event.data.flowId
          }
        );
      }
    },
    false
  );
})();
